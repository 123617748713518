// Add your theme styles here.
// See https://theme-ui.com/theme-spec.
export default {
  colors: {
    text: "#263238",
    headerPrimary: "white",
    headerButton: "#263238",
    buttonPrimaryBg: "#0098f9",
    buttonPrimaryBgHover: "#0070b8",
    buttonPrimaryColor: "white",
    buttonSecondaryBg: "#263238",
    buttonSecondaryBgHover: "gray",
    buttonSecondaryColor: "white",
    link: "#0098f9",
    linkHover: "#0070b8",
  },
}
